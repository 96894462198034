<template>
  <div id="SLACompliance" class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="pageTitle">SLA Compliance</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">SLA Compliance ({{ totalRecords | thousandSeparator }})</div>
          <div class="card-body">
            <!--  <b-spinner variant="secondary" label="Spinning"></b-spinner> -->
            <div>
              <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                @on-search="onSearch"
                :totalRows="totalRecords"
                :columns="columns"
                :rows="reports"
                :isLoading.sync="spinner"
                :line-numbers="true"
                :search-options="{
                  enabled: true,
                  trigger: 'enter',
                  placeholder: 'Search this list...'
                }"
                :pagination-options="{
                  mode: 'pages',
                  enabled: true,
                  perPage: 100,
                  perPageDropdown: [25, 50, 75, 100]
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: false
                }"
                styleClass="vgt-table condensed myGoodTable"
              >
                <div slot="table-actions">
                  <div class="input-group mb-2 mr-sm-2">
                    <label class="mt-2 ml-5">Date Range:</label>
                    <RangedatePicker @selected="selectedDateRange" i18n="EN" class="" righttoleft="true" />
                    <button @click.prevent="downloadFunction" class="btn btn-sm btn-secondary ml-2" :disabled="downloading">
                      <span v-if="!downloading">Download</span>
                      <span class="spinner-border spinner-border-sm" role="status" v-if="downloading">
                        <span class="sr-only">Loading...</span>
                      </span>
                    </button>
                  </div>
                </div>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import RangedatePicker from 'vue-rangedate-picker'
import ISO8601Date from '../../helper/ISO8601Date'

export default {
  name: 'SLACompliance',

  components: {
    RangedatePicker
  },

  data() {
    return {
      reports: [],
      reportsBK: [],
      name: '',
      description: '',
      spinner: false,
      downloading: false,
      startDate: '',
      endDate: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: ''
          }
        ],
        page: 1,
        perPage: 100
      },
      firstID: 0,
      lastID: 0,
      myparam: {}
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.myparam = { ...this.myparam, goodTableParams: JSON.stringify(this.serverParams), firstID: this.firstID, lastID: this.lastID, start: this.startDate, end: this.endDate }
      let searchType = this.$route.query.searchType
      let searchTerm = this.$route.query.searchTerm
      if (searchType !== '' && searchType !== undefined) {
        this.myparam = { ...this.myparam, searchType: searchTerm, searchTerm: searchTerm }
      }

      this.spinner = true
      axios
        .get('/reports/sla-compliance', {
          params: this.myparam
        })
        .then(res => {
          if (res.data.status == 'success') {
            this.reports = res.data.reports
            this.reportsBK = res.data.reports
            this.totalRecords = res.data.totalRecords
            if (this.lastID === 0) {
              this.firstID = res.data.reports[0].ID
              this.lastID = res.data.reports[res.data.reports.length - 1].ID
            }
          }
          this.spinner = false
        })
    },

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
      //this.loadData()
    },

    downloadFunction() {
      if (this.startDate === '' || this.endDate === '') {
        this.$swal('Date required!', 'Please select a date range.', 'error')
        return
      }
      this.downloading = true
      axios.post(`/reports/sla-compliance?start=${this.startDate}&end=${this.endDate}`).then(res => {
        if (res.data.status === 'failed') {
          this.$swal('Opps', res.data.message, 'error')
          return
        }
        // Download file from res.data
        // https://stackoverflow.com/questions/43432892/force-download-get-request-using-axios
        // https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c
        let blob = new Blob([res.data], { type: 'text/csv' })
        let url = window.URL.createObjectURL(blob)
        window.open(url, '_blank')
        this.downloading = false
      })
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadData()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadData()
    },

    onSortChange(params) {
      if (params[0].type === 'desc') {
        this.reports.reverse()
        return
      }

      const field = params[0].field
      this.reports.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      })
    },

    onSearch(params) {
      let reports = []
      let searchTerm = params.searchTerm

      reports = this.reportsBK.filter(el => {
        return el.DateIn.includes(searchTerm) || el.TimeIn.includes(searchTerm) || el.Subject.includes(searchTerm) || el.Customer.includes(searchTerm) || el.Email.includes(searchTerm) || el.Channel.includes(searchTerm) || el.Department.includes(searchTerm) || el.Status.includes(searchTerm) || el.Comment.includes(searchTerm) || el.Ref.includes(searchTerm) || el.Age.includes(searchTerm) || el.AgentName.includes(searchTerm) || el.DateSentToSecondLevel.includes(searchTerm) || el.TimeSentToSecondLevel.includes(searchTerm) || el.DateAssigned.includes(searchTerm) || el.TimeAssigned.includes(searchTerm) || el.DateResolved.includes(searchTerm) || el.TimeResolved.includes(searchTerm) || el.DateTreated.includes(searchTerm) || el.TimeTreated.includes(searchTerm) || el.Category.includes(searchTerm) || el.SubCategory.includes(searchTerm)
      })

      this.reports = reports
    }
  },

  computed: {
    columns() {
      let cols = [
        {
          label: 'Date In',
          field: 'DateIn',
          sortable: true
        },
        {
          label: 'Time In',
          field: 'TimeIn',
          sortable: true
        },

        {
          label: 'Subject',
          field: 'Subject',
          sortable: true
          //width: "20%",
        },

        {
          label: 'Bank/Merchant',
          field: 'Customer',
          sortable: true
        },

        {
          label: 'Email',
          field: 'Email',
          sortable: true
        },
        {
          label: 'Channel',
          field: 'Channel',
          sortable: true
        },

        {
          label: 'Dependency',
          field: 'Department',
          sortable: true
        },

        {
          label: 'Status',
          field: 'Status',
          sortable: true
        },

        {
          label: 'Comment',
          field: 'Comment',
          sortable: false
        },

        {
          label: 'Unique ID',
          field: 'Ref',
          sortable: true
        },

        {
          label: 'Age',
          field: 'Age',
          sortable: false
        },

        {
          label: 'Agent Name',
          field: 'AgentName',
          sortable: true
        },

        {
          label: 'Date Sent To 2nd Level',
          field: 'DateSentToSecondLevel',
          sortable: true
        },
        {
          label: 'Time Sent To 2nd Level',
          field: 'TimeSentToSecondLevel',
          sortable: true
        },

        {
          label: 'Date Assigned',
          field: 'DateAssigned',
          sortable: true
        },
        {
          label: 'Time Assigned',
          field: 'TimeAssigned',
          sortable: true
        },

        {
          label: 'Date Resolved',
          field: 'DateResolved',
          sortable: true
        },
        {
          label: 'Time Resolved',
          field: 'TimeResolved',
          sortable: true
        },

        {
          label: 'Date Treated',
          field: 'DateTreated',
          sortable: true
        },
        {
          label: 'Time Treated',
          field: 'TimeTreated',
          sortable: true
        },
        {
          label: 'Category',
          field: 'Category',
          sortable: true
        },
        {
          label: 'Subcategory',
          field: 'SubCategory',
          sortable: true
        }
      ]

      return cols
    }
  }
}
</script>

<style></style>
